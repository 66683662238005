<template>
  <div>
    <div class="swiper-container my-swiper" id="js_mySwiper">
      <div class="swiper-wrapper">
        <div class="swiper-slide" v-for="(item,i) in sliders" :key="i" :style="{'background-image':'url(' + item + ')'}"></div>
      </div>
      <div class="swiper-pagination swiper-pagination-white"></div>
    </div>
    <div>
      <h2 class="home-title">平台天游线路检测中心-欢迎光临</h2>
      <prod-list></prod-list>
    </div>
    <div>
      <h2 class="home-title">运营服务</h2>
      <items-list :items="service"></items-list>
    </div>
    <div>
      <h2 class="home-title">开放生态</h2>
      <items-list :items="system"></items-list>
    </div>
    <div>
      <h2 class="home-title">他们都在优讯</h2>
      <banks swiperId="js_bankSwiper"></banks>
    </div>
  </div>
</template>

<script>
import { banks, prodList,itemsList } from '@/components'
import service from '@/assets/js/service.js';
import system from '@/assets/js/system.js';
import Swiper from 'swiper';
import 'swiper/dist/css/swiper.min.css'

export default {
  components: {
    banks, prodList, itemsList
  },
  data () {
    return {
      service, system,
      sliders:[
        require('@/assets/images/home/swiper1.jpg'),
        require('@/assets/images/home/swiper2.jpg'),
        require('@/assets/images/home/swiper3.jpg')
      ]
    }
  },
  mounted () {
    let t = this
    if(!t.$lowie){
      const swiper = new Swiper('#js_mySwiper', {
        pagination: '.swiper-pagination',
        paginationClickable: true,
        loop: true,
        speed: 600,
        autoplay: 4000,
        observer:true,//修改swiper自己或子元素时，自动初始化swiper
        observeParents:true,//修改swiper的父元素时，自动初始化swiper
        onTouchEnd: function() {
          // touchend的时候轮播图可能还在滑动，立即执行startAutoplay以后不起作用
          // 所以加个时间
          setTimeout(swiper.startAutoplay,600)
        }
      })
    }
  },
  methods: {

  }
}
</script>

<style lang="scss">
  @import '@/assets/scss/_variables.scss';
  @import '@/assets/scss/_extend.scss';
  .my-swiper{
    height: 400px;
    margin-bottom: 30px;
    .swiper-slide{
      height: 400px;
      background-size: auto 100%;
      background-position: center center;
      background-repeat: no-repeat;
    }
    .swiper-pagination-bullet{
      width: 12px;
      height: 6px;
      border-radius: 6px;
      opacity: 0.5;
      &.swiper-pagination-bullet-active{
        width: 18px;
        opacity: 1;
      }
    }
  }
  .home-title{
    line-height: 1;
    text-align: center;
    font-size: 30px;
    font-weight: normal;
    padding: 50px 0;
  }

  @media screen and (max-width:1200px) {
    .my-swiper{
      height: px2rem(120px);
      margin-bottom: px2rem(15px);
      .swiper-slide{
        height: px2rem(120px);
      }
      .swiper-pagination.swiper-pagination-bullets{
        bottom: px2rem(5px);
      }
      .swiper-pagination-bullet{
        width: px2rem(6px);
        height: px2rem(2px);
        border-radius: px2rem(2px);
        &.swiper-pagination-bullet-active{
          width: px2rem(10px);
        }
      }
    }
    .home-title{
      line-height: 1;
      font-size: px2rem(20px);
      padding: px2rem(25px) 0;
    }
  }
</style>
